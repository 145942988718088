import './bootstrap';

import Alpine from 'alpinejs';
//import toastr from 'toastr';

window.Alpine = Alpine;

Alpine.start();

/** Jquery and Datatables */
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import jQuery from 'jquery';
import jqueryDataTables from 'datatables.net-dt';
//import select2 from 'select2';

window.jQuery = window.$ = jQuery;

//window.toast = toastr;